export function hasFiltersApplied(
  searchFilter,
  filteredStatus,
  filteredServices
) {
  return !(
    searchFilter.length < 3 &&
    Object.keys(filteredStatus).length === 0 &&
    Object.keys(filteredServices).length === 0
  );
}

/**
 *
 * @param {*} update
 * @param {*} filterObject
 * @returns true if no filter or if key is in filteredObject and value is true
 */
export function isKeyChecked(key, filterObject) {
  return (
    Object.keys(filterObject).length === 0 ||
    (key in filterObject && filterObject[key])
  );
}

export function isSearchFilterIncluded(update, searchFilter) {
  const search = searchFilter.trim().toLowerCase();
  if (search.length < 3) return true;

  const title = update.title.toLowerCase() || "";
  const service = update.service.toLowerCase() || "";
  const status = update.status ? update.status.toLowerCase() : "";
  const details = update.detailsHTML.toLowerCase() || "";
  const searchIn = [title, service, status, details];
  return searchIn.some((s) => s.includes(search));
}

export function selectAll(keysList, setter) {
  const newFilter = {};
  keysList.forEach((key) => {
    newFilter[key.name] = true;
  });
  setter(newFilter);
}
