export default function Filter({
  month,
  setMonth,
  year,
  setYear,
  maxUpdates,
  setMaxUpdates,
  onlyNew,
  setOnlyNew,
  fetchItems,
  loading,
  filteredUpdates,
}) {
  return (
    <div className="row mb-4">
      <div className={"col-auto"}>
        <div className="input-group">
          <div className="input-group-text">Month:</div>
          <select
            className="form-select"
            value={month}
            onChange={(e) => {
              const selectedMonth = parseInt(e.target.value);
              setMonth(selectedMonth);
            }}
          >
            {Array.from({length: 12}, (_, i) => (
              <option key={11 - i} value={11 - i}>
                {new Date(0, 11 - i).toLocaleString("default", {
                  month: "long",
                })}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={"col-auto"}>
        <div className="input-group">
          <div className="input-group-text">Year:</div>
          <select
            className="form-select"
            value={year}
            onChange={(e) => {
              setYear(parseInt(e.target.value));
            }}
          >
            {Array.from({length: 4}, (_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className={"col-auto"}>
        <div className="input-group">
          <div className="input-group-text">Max:</div>
          <input
            type="number"
            className="form-control"
            value={maxUpdates}
            onChange={(e) => setMaxUpdates(parseInt(e.target.value))}
            style={{width: 72}}
          />
        </div>
      </div>
      <div className={"col-auto"}>
        <div className="form-check form-switch mt-2">
          <label
            className="form-check-label"
            htmlFor={"only-new"}
            style={{cursor: "pointer"}}
          >
            Only new
          </label>

          <input
            className="form-check-input"
            type={"checkbox"}
            role={"switch"}
            checked={onlyNew}
            onChange={(e) => setOnlyNew(e.target.checked)}
            id={"only-new"}
            style={{cursor: "pointer"}}
          />
        </div>
      </div>
      <div className="col-auto">
        <button
          className="btn btn-primary"
          onClick={fetchItems}
          style={{width: 120}}
        >
          {loading && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;&nbsp;
            </>
          )}

          {loading ? "Loading" : "Search"}
        </button>
      </div>
      <div className="col-auto mt-2">
        <span>{filteredUpdates.length} results</span>
      </div>
    </div>
  );
}
