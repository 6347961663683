import React from "react";
import {useEffect, useState} from "react";

import config from "./config";
import UpdatePPT from "./Update/UpdatePPT";
import axios from "axios";
import "./App.css";
import NavBar from "./NavBar/NavBar";
import SideBar from "./SideBar/SideBar";

import {
  hasFiltersApplied,
  isKeyChecked,
  isSearchFilterIncluded,
} from "./searchHelper";
import Footer from "./Footer/Footer";
import Filter from "./Filter/Filter";

function App() {
  const {getUpdatesEndpoint} = config;

  const [updates, setUpdates] = useState([]);
  const [filteredUpdates, setFilteredUpdates] = useState([]);

  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();

  const [month, setMonth] = useState(
    currentDay < 7 ? (currentMonth === 0 ? 11 : currentMonth - 1) : currentMonth
  );
  const [year, setYear] = useState(new Date().getFullYear());
  const [maxUpdates, setMaxUpdates] = useState(150);

  const [onlyNew, setOnlyNew] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [loadTime, setLoadTime] = useState(0);
  const [errors, setErrors] = useState([]);

  // Filters
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredStatus, setFilteredStatus] = useState({});
  const [filteredServices, setFilteredServices] = useState({});

  const setError = (error) => {
    setErrors([...errors, new Date().toLocaleTimeString() + ": " + error]);
  };

  const fetchItems = async () => {
    setLoading(true);
    const startTime = Date.now();
    await axios
      .get(
        `${getUpdatesEndpoint}?onlyNew=${JSON.stringify(
          onlyNew
        )}&maxUpdates=${maxUpdates}&month=${month}&year=${year}&userId=${
          user.userId
        }`
      )
      .then(({data: updates}) => {
        const servicesSet = new Set(updates.map((update) => update.service));
        const servicesArr = Array.from(servicesSet).map((service) => {
          return {
            name: service,
            displayName: service.startsWith("Azure")
              ? service.substr(6)
              : service,
          };
        });

        setUpdates(updates);
        servicesArr.sort((a, b) => {
          if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
            return -1;
          else return 1;
        });
        setServices(servicesArr);
        setLoadTime(Date.now() - startTime);
        setLoading(false);
      })
      .catch((e) => {
        const errorMessage = `Error fetching updates on ${getUpdatesEndpoint}: ${e.message}`;
        setError(errorMessage);
        console.log(e);
      });
  };

  useEffect(() => {
    user && fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function getUserInfo() {
    const response = await fetch("/.auth/me");
    const payload = await response.json();
    const {clientPrincipal} = payload;
    console.log(clientPrincipal);
    setUser(clientPrincipal);
  }

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFilter = (update) => {
    if (hasFiltersApplied(searchFilter, filteredStatus, filteredServices)) {
      if (!isKeyChecked(update.status, filteredStatus)) return false;
      if (!isKeyChecked(update.service, filteredServices)) return false;
      if (!isSearchFilterIncluded(update, searchFilter)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (!hasFiltersApplied(searchFilter, filteredStatus, filteredServices)) {
      setFilteredUpdates(updates);
      return;
    }

    const fUpdates = updates.filter(updateFilter);
    setFilteredUpdates(fUpdates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates, searchFilter, filteredStatus, filteredServices]);

  return (
    <div>
      <NavBar user={user} />
      {!user && (
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 align-self-center mt-4">
            <div className="alert alert-danger" role="alert">
              <strong>Ooops!</strong> You need to login to see the updates
            </div>
          </div>
        </div>
      )}
      {user && (
        <div className="d-flex">
          <SideBar
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            filteredStatus={filteredStatus}
            setFilteredStatus={setFilteredStatus}
            filteredServices={filteredServices}
            setFilteredServices={setFilteredServices}
            services={services}
          />

          <div className="d-flex flex-column">
            <div className={"row m-1 mt-3"}>
              <Filter
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
                maxUpdates={maxUpdates}
                setMaxUpdates={setMaxUpdates}
                onlyNew={onlyNew}
                setOnlyNew={setOnlyNew}
                fetchItems={fetchItems}
                loading={loading}
                filteredUpdates={filteredUpdates}
              />

              <div className="row" style={{width: "calc(100vw - 300px)"}}>
                {filteredUpdates.map((update) => (
                  <UpdatePPT
                    update={update}
                    key={update.id}
                    triggerReload={fetchItems}
                    setError={setError}
                  />
                ))}
              </div>
              {filteredUpdates.length === 0 && (
                <div className="">
                  <div className="mt-4 alert alert-primary" role="alert">
                    <strong>All done!</strong> It looks like all updates are
                    already checked.
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer
            amountUpdates={filteredUpdates.length}
            loadTime={loadTime}
            onlyNew={onlyNew}
            month={month}
            year={year}
            maxUpdates={maxUpdates}
            amountServices={services.length}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      )}
    </div>
  );
}

export default App;
